import React from 'react';
import styled from 'styled-components';

const Header: React.FC<any> = ({ variant = "default", className, children }) => {
  return (
    <header className={className}>
      {children}
    </header>
  )
}

export default styled(Header)`
  display: flex;
  padding: 15px;
  background-color: ${({ theme, variant }) => theme.palette(variant)};
  color: ${({ theme, variant }) => theme.font.color(variant)}
`;