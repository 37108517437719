import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { store } from './store';
import { theme } from './theme';
import { Router } from './Router';
import { GlobalStyles } from './components';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router></Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
