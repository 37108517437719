import { Theme } from "../../theme";

const getFontWeightFromLevel = (level?: number | string) => {
  switch (level) {
    case 1:
      return 700;
    case 2:
      return 600;
    case 3:
      return 600;
    case 4:
      return 500;
    default:
      return 400;
  }
};

export type CalculateFontWeightProps = {
  theme: Theme;
  weight?: number | "bold" | "normal" | "bolder";
  level?: number | "p";
}

export const calculateFontWeight = ({
  theme,
  weight,
  level
}: CalculateFontWeightProps): number | string =>
  theme.font.weight(weight || typeof level === 'number' ?
    getFontWeightFromLevel(level)
    : (theme.fontWeights['default'])
  );