import {
  LOGIN_SUCCESSFUL
} from './constants';

import * as initialState from './state';

export function account(state = initialState.account, actions: any) {
  return Object.assign({}, state, actions.account);
}

export function profile(state = initialState.profile, actions: any) {
  return Object.assign({}, state, actions.profile);
}

export function login(payload: any) {
  return {
    type: LOGIN_SUCCESSFUL,
    profile: Object.assign({}, payload.attributes),
    account: Object.assign({}, {
      isAuthenticated: true,
      id: payload.username,
      ...payload.signInUserSession
    })
  }
}