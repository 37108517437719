
import {
  DEFAULT_BREAKPOINTS,
  DEFAULT_FONT_FAMILIES,
  DEFAULT_PALETTES,
  DEFAULT_THEME_OPTIONS,
  MEDIA_QUERIES
} from './defaults';

type PaletteName = keyof (typeof DEFAULT_PALETTES);
type FontName = keyof (typeof DEFAULT_FONT_FAMILIES);
type Breakpoints = typeof DEFAULT_BREAKPOINTS;
type Fonts = typeof DEFAULT_FONT_FAMILIES;
type Palettes = typeof DEFAULT_PALETTES;
type ThemeOptions = typeof DEFAULT_THEME_OPTIONS;

export type ThemeProps = {
  breakpoints: Breakpoints;
  fonts: Fonts;
  mode: "light" | "dark";
  variants: Palettes;
}
export type ThemeRecord<K extends string | number | symbol, V = any> = Record<K, V>;

export type FontOptions = {
  fontFamilies: Fonts;
  fontSizes: ThemeOptions['fontSizes'];
  fontWeights: ThemeOptions['fontWeights'];
  variants: Palettes;
  defaultColor: ThemeOptions['palette'];
}

function fontFactory({
  fontFamilies,
  fontSizes,
  fontWeights,
  variants,
  defaultColor
}: FontOptions) {
  return {
    family: (font: FontName) => {
      return fontFamilies[font] || fontFamilies['roboto'];
    },
    weight: (weight?: number | string) => {
      const fontWeight = fontWeights['default'];
      return weight || fontWeight;
    },
    size: (size?: number | string) => {
      const fontSize = fontSizes['default'];
      return size || fontSize;
    },
    color: (variant?: PaletteName) => {
      const color = defaultColor;
      return variant ?
        variants[variant]['color'] :
        color['color'];
    }
  }
}

export function createTheme<P>({ ...options }: ThemeProps & P) {
  const opts = { ...DEFAULT_THEME_OPTIONS, ...options };

  const font = fontFactory({
    fontFamilies: opts.fonts as Fonts,
    fontSizes: opts.fontSizes as ThemeOptions['fontSizes'],
    fontWeights: opts.fontWeights as ThemeOptions['fontWeights'],
    variants: opts.variants as Palettes,
    defaultColor: opts.palette as ThemeOptions['palette']
  });

  return {
    ...opts,
    font,
    spacing: (size: number) => {
      return size * opts.spacing;
    },
    palette: (variant: PaletteName) => {
      const variants = opts.variants;
      const mode = opts.mode;
      const palette = opts.palette;
      return variant ?
        variants[variant][mode] :
        palette[mode];
    },
    breakpoints: {
      ...opts.breakpoints,
      ...MEDIA_QUERIES
    }
  };
}

export const theme = createTheme({
  breakpoints: DEFAULT_BREAKPOINTS,
  fonts: DEFAULT_FONT_FAMILIES,
  mode: (process.env.REACT_APP_COLOR_MODE as "light" | "dark") || 'light',
  variants: DEFAULT_PALETTES
});

export type Theme = typeof theme;