/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ef88832b-cff7-443d-8803-1025e95337f2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VTyiNKTiw",
    "aws_user_pools_web_client_id": "4nm4hnoi16c66vt67h2uvbq10f",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://3hhwobm6wfbq7httsfk7mgpqqi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
