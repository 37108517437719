import { up, down, only, between } from 'styled-breakpoints';

export const DEFAULT_PALETTES = {
  default: {
    light: '#1D3557',
    dark: '#0F1C2E',
    color: '#fff'
  },
  primary: {
    light: '#457B9D',
    dark: '#2C4E63',
    color: '#fff'
  },
  secondary: {
    light: '#A8DADC',
    dark: '#7CC8CB',
    color: '#fff'
  },
  accent: {
    light: '#F1FAEE',
    dark: '#CBEDC0',
    color: '#000'
  },
  success: {
    light: '#70e000',
    dark: 'darkgreen',
    color: '#fff'
  },
  warning: {
    light: '#ffbd00',
    dark: 'darkyellow',
    color: '#fff'
  },
  danger: {
    light: '#E42535',
    dark: '#B61624',
    color: '#fff'
  }
};

export const DEFAULT_BREAKPOINTS = {
  xs: '320px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1440px'
};

export const DEFAULT_FONT_FAMILIES = {
  oxygen: "'Oxygen', sans-serif",
  cantarell: "'Cantarell', sans-serif",
  fire: "'Fira Sans', sans-serif",
  roboto: "'Roboto', 'Oxygen', sans-serif",
  ubuntu: "'Ubuntu', sans-serif"
};


const palette = DEFAULT_PALETTES['default'];

export const DEFAULT_THEME_OPTIONS = {
  breakpoints: DEFAULT_BREAKPOINTS,
  palette,
  mode: 'dark' as "dark" | "light",
  fontSizes: {
    1: '2.25rem',
    2: '2rem',
    3: '1.75rem',
    4: '1.5rem',
    5: '1.25rem',
    6: '1rem',
    default: '0.75rem'
  },
  fontWeights: {
    default: 400,
    100: 100,
    200: 200,
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
    800: 800,
    bold: 'bold',
    bolder: 'bolder',
    normal: 'normal'
  },
  spacing: 3
};

export const MEDIA_QUERIES = {
  up,
  down,
  only,
  between
}
