import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from './root';
import { middlewares } from './middleware';
import { composeWithDevTools } from 'redux-devtools-extension'

export { login } from './user';

const enhancers = composeWithDevTools({ trace: true });

const middleware = process.env.NODE_ENV !== "production" ?
  enhancers(applyMiddleware(...middlewares))
  : applyMiddleware(...middlewares);

export const store = createStore(rootReducer, middleware);