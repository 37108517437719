import React from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Action, ActionCreator } from 'redux';

export function withAuthentication<T extends ActionCreator<Action>>(action: T) {

  const WithAuthentication = (Component: React.ElementType) => {

    const withAuth = React.forwardRef((props, ref) => {

      const [dispatched, setDispatched] = React.useState(false);

      const dispatch = useDispatch();

      const authenticationEffect = () => {
        const getAuthenticatedUser = async () => {
          try {
            const user = await Auth.currentAuthenticatedUser();

            if (user) {
              dispatch(action(user));
            }
          } catch (e) { }
          setDispatched(true);
        }

        if (!dispatched) {
          getAuthenticatedUser();
        }
      };

      React.useEffect(authenticationEffect, [dispatch, dispatched]);

      return (
        <React.Fragment>
          {(dispatched && <Component {...props} ref={ref} />)}
        </React.Fragment>
      );
    });
    return withAuth;
  };

  return WithAuthentication;
}