import { RouteProps } from 'react-router-dom';
import loadable, { LoadableComponent } from '@loadable/component';


export interface Page {
  Component: LoadableComponent<any>;
  authRequired?: boolean;
  routeProps?: Partial<RouteProps>
}

export const pages: Page[] = [
  {
    Component: loadable(() => import('./Home')),
    authRequired: true,
    routeProps: {
      path: '/',
      exact: true
    }
  },
  {
    Component: loadable(() => import('./Login')),
    authRequired: false,
    routeProps: {
      path: '/login',
      exact: false
    }
  }
];
