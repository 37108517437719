import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pages } from './pages';
import { withAuthentication } from './utils';
import { login } from './store';

export const Router = withAuthentication(login)(() => {
  const account = useSelector((state: any) => state.user.account);
  return (
    <BrowserRouter>
      <Switch>
        {pages.map(({ Component, authRequired, routeProps }, i) => (
          <Route
            key={i}
            {...routeProps}
            render={({ location }) => (
              (authRequired && !account.isAuthenticated) ?
                <Redirect to={{
                  pathname: '/login',
                  state: { from: location }
                }}></Redirect>
                : <Component></Component>
            )}>
          </Route>
        ))}
      </Switch>
    </BrowserRouter>
  );
});