import React from 'react';
import styled from 'styled-components';
import { calculateFontSize, calculateFontWeight } from '../../utils';

export type TypographyProps = {
  tag?: "div" | "span" | "p" | "blockquote" | "figcaption";
  level?: 1 | 2 | 3 | 4 | 5 | 6 | "default";
  weight?: "normal" | "bold" | "bolder" | number;
  size?: number;
  family?: string;
  color?: string;
  className?: string;
}

const Typography: React.FC<TypographyProps> = React.forwardRef(({
  tag = "div",
  level = "default",
  className,
  children
}, ref: any) => {
  const Tag = level ?
    (level === "default") ?
      "p" as React.ElementType<any> :
      `h${level}`
    : tag;
  return (
    <Tag className={className} ref={ref}>
      {children}
    </Tag>
  )
});

if (process.env.NODE_ENV !== "production") {
  Typography.displayName = "Typography";
}

export default styled(Typography)`
  font-family: ${({ theme, family }) => theme.font.family(family)};
  font-weight: ${calculateFontWeight};
  color: ${({ theme, color }) => theme.font.color(color)};
  font-size: ${calculateFontSize};
  transition: font-size 0.1s;
  margin-block: 0;
  & > * {
    margin-block: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: calc(${calculateFontSize} * 0.75);
    transition: font-size 0.1s;
  }

  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: calc(${calculateFontSize} * 0.85);
    transition: font-size 0.1s;
  }

  ${({ theme }) => theme.breakpoints.only('lg')} {
    font-size: calc(${calculateFontSize} * 1.3);
    transition: font-size 0.1s;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    font-size: calc(${calculateFontSize} * 1.4);
    transition: font-size 0.1s;
  }
`;