import { Theme } from "../../theme";

export type CalculateFontSizeProps = {
  theme: Theme;
  size?: number;
  level?: keyof Theme['fontSizes'];
}

export const calculateFontSize = ({
  theme,
  size,
  level
}: CalculateFontSizeProps) => theme.font.size(
  size || theme.fontSizes[level as keyof Theme['fontSizes']]
) as number;